class Slip
  constructor: (@params) ->
    do @init
    do @events

  init: ->
    @element    = $(@params.el)
    @buttons    = @element.find '.open-button'
    @nav        = @element.find 'nav a'
    @menuButton = @element.find '.menu li.menu-btn'
    @pusher     = $('.pusher')
    @mobile     = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    @pages   = @element.find 'section'
    @lastAnim   = 0
    @sizeWindow = $( window ).width()

  events: ->
    self = this
    @buttons.on 'touchstart click', (e) ->
      e.stopPropagation()
      e.preventDefault()
      _cur = $(this).index()
      self.element.toggleClass 'open'
      self.pusher.removeClass 'show-down'
      self.changePage(_cur)
      self.menuButton.removeClass 'active'


    @nav.on 'touchstart click', (e) ->
      e.stopPropagation()
      e.preventDefault()
      self.changePage($(this).index())
  changePage: (i) ->

    @pages.eq(@curent).removeClass 'active'
    @nav.eq(@curent).removeClass 'active'
    @curent = if i != undefined then i else 0
    @pages.eq(@curent).addClass 'active'
    @nav.eq(@curent).addClass 'active'

    if @changeLogo
      logo = @buttons.eq(i).data('logo')
      if logo == 'cleaning'
        @logo.eq(1).addClass('active').siblings('img').removeClass 'active'
        @text.eq(1).addClass('active').siblings('p').removeClass 'active'
        @licenseBlocks.removeClass 'active'
        @cleaning.addClass('active')

      else if logo == 'ceitiring'
        @logo.eq(2).addClass('active').siblings('img').removeClass 'active'
        @text.eq(2).addClass('active').siblings('p').removeClass 'active'
        @licenseBlocks.removeClass 'active'
        @keitering.addClass('active')


class Right extends Slip
  init:->
    super
    @curent  = 0
    @changeLogo = true
    @article = @element.find 'article'

    @about = $('.about')
    @logo = @about.find('.logo img')
    @text = @about.find '.block p'
    @licenseBlocks = @about.find '.license-block'
    @cleaning = @about.find '.cleaning'
    @keitering = @about.find '.keitering'
    @group = @about.find '.group'


    do @backBtn
    do @changePage


  backBtn: ->
    @backBtn = $('a.back-btn')
    @backBtn.on 'click touchstart', (e) =>
      e.stopPropagation()
      e.preventDefault()
      @element.removeClass 'open'
      @logo.eq(0).addClass('active').siblings('img').removeClass 'active'
      @text.eq(0).addClass('active').siblings('p').removeClass 'active'
      @licenseBlocks.removeClass 'active'
      @group.addClass('active')

class Bottom extends Slip
  init: ->
    super
    @downContents       = @pusher.find '.down-content section'
    @curentDownContent  = 0
    @mainBlock          = @pusher.find '.main-block'
    @otherButtons       = $('.menu li').not '.menu-btn'

  events: ->
    super
    @element.on 'mousewheel DOMMouseScroll MozMousePixelScroll', (event) =>
      do event.preventDefault
      delta = event.originalEvent.wheelDelta || -event.originalEvent.detail
      timeNow = new Date().getTime()
      if timeNow - @lastAnim < 1000
        do event.preventDefault
        return
      @lastAnim = timeNow
      if delta < 0
        @element.addClass 'open'
      else
        @pusher.removeClass 'show-down'
        @element.removeClass 'open'
        @menuButton.removeClass 'active'


    self = this
    @menuButton.on 'click touchstart', ->
      self.pusher.addClass 'show-down'
      self.menuButton.eq(self.curentDownContent).removeClass 'active'
      self.downContents.eq(self.curentDownContent).removeClass 'active'
      self.curentDownContent = if $(this).index() != undefined then $(this).index() - 1 else 0
      self.menuButton.eq(self.curentDownContent).addClass 'active'
      self.downContents.eq(self.curentDownContent).addClass 'active'

      # if self.sizeWindow <= 767
      #   $('.left-block').removeClass 'open'
    # @otherButtons.on 'click', ->
    #   switch $(this).index()
new Bottom({
  el: '.left-block'
})

new Right({
  el: '.pusher'
})


class License
  constructor: (@params) ->
    do @init
  init: ->
    @li = @params.el.find 'li a'
    @number = @params.el.index()
    @companyLi = $('.box .image-box a')
    @curLic = 0

    do @buildPopupLicense
    do @events

  buildPopupLicense: ->
    $('body').append '
      <div class="popup" id="popup-licence-'+@number+'">
        <span class="arrow left"></span>
        <img src="">
        <span class="arrow right"></span>
        <span class="close"></span>
      </div>'

    # Popup variables
    @popup        = $('#popup-licence-'+@number)
    @closePopup   = @popup.find '.close'
    @imagePopup   = @popup.find 'img'
    @prevBtnPopup = @popup.find '.left'
    @nextBtnPopup = @popup.find '.right'
  events: ->
    self = this

    @li.on 'click', ->
      self.popup.addClass 'show'
      self.curLic = $(this).parent('li').index()
      self.imagePopup.attr('src', self.li.eq(self.curLic).data('large'))

    @companyLi.on 'click', ->
      $('#popup-licence-0').addClass 'show'
      self.curLic = $(this).parents('.box').index() - 1
      self.imagePopup.attr('src', self.li.eq(self.curLic).data('large'))

    @closePopup.on 'click', ->
      self.popup.removeClass 'show'

    @prevBtnPopup.on 'click', ->
      self.curLic--
      if self.curLic < 0
        self.curLic = self.li.length - 1
      self.imagePopup.attr('src', self.li.eq(self.curLic).data('large'))

    @nextBtnPopup.on 'click', ->
      self.curLic++
      if self.curLic >= self.li.length
        self.curLic = 0
      self.imagePopup.attr('src', self.li.eq(self.curLic).data('large'))


new License( el: $('.license-block').eq(0) )
new License( el: $('.license-block').eq(1) )
new License( el: $('.license-block').eq(2) )




right = $('.right-content')
sections = right.find 'section'
showSections = sections.find '.abs > div'
hideSections = sections.find '.hide-blocks'
buttonShow = hideSections.find '.open-objects'
buttonHide = hideSections.find '.close-objects'

buttonShow.on 'click touchstart', (e) ->
  e.stopPropagation()
  e.preventDefault()
  $(this).parent('.objects').addClass('show')
  active = right.find 'section.active'
  active.find('.abs > div').eq($(this).parent('.objects').index()).addClass('hide')

buttonHide.on 'click touchstart', (e) ->
  e.stopPropagation()
  e.preventDefault()
  $(this).parents('.objects').removeClass('show')
  active = right.find 'section.active'
  active.find('.abs > div').eq($(this).parents('.objects').index()).removeClass('hide')

nav = $('.navigation')
links = nav.find('a')
links.on 'click touchstart', (e) ->
  e.stopPropagation()
  e.preventDefault()
  showSections.removeClass('hide')
  hideSections.find('.objects').removeClass('show')


class BackToTop
  constructor: () ->
    do @init
    do @events

  init: ->
    @eventBlock = $('.block-events')
    @button = $('#back-to-top')

  events: ->
    @button.on 'click', (e) =>
      @eventBlock.animate({
        scrollTop: 0
      }, 300)
    self = this
    @eventBlock.scroll( () ->

      if ($(this).scrollTop() > 150)
        self.button.fadeIn();
      else
        self.button.fadeOut();
    )



new BackToTop()